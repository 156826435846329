import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../App/components/layout';
import SignUpForm from '../components/SignUp';
import SEO from "../App/components/seo"

export default ({data}) => (
  <Layout>
    <SEO title="" />
    <SignUpForm terms={data.craft.entry.body.content} />
  </Layout>
);

export const data = graphql`
query{
  craft{
    entry(slug: "terms"){
        ...on Craft_Terms{
          body{
            content
          }
        }
      }
  }
}
`;
